/* HOTEL ROOM LIST - MOBILE FIRST (~ TABLET) */
.hotel-room-list,
.hotel-room-recommended-container {
    padding-bottom: 45px;
    position: relative;

    .c-hotel-room-list {
        background-color: @body-bg;
        width: 100%;
        margin: 0px auto 24px;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        position: relative;
        .mod--rounded-skin__class;
        border: 1px solid #D7D9DA;
        &__content-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &__header {
            width: 38%;
            position: relative;

            width: 100%;
            .c-label {
                width: ~"calc(100% - 8px)";
                margin: 4px;
            }

            .c-container-image {
                width: 100%;
                height: 100%;

                height: 220px;
                width: auto;
                &.mod--gradient-overlay {
                    .cmp-image {
                        &::after {
                            content: none; //PLASOFT-15851
                        }
                    }
                }
            }
        }

        &__body {
            padding: 14px;
            width: 100%;

            &-header {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                overflow: visible;
                margin-bottom: -5px;
                width: 100%;
                & > .c-hotel-room-list__body-persons { //people mobile
                    .sprite-icon-people {
                        height: 22px;
                        background-position: -8px -1266px;
                        transform: scale(1.13);
                    }
                }
            }

            &-title {
                font-size: 18px;
                line-height: 28px;
                font-weight: 600;
                text-decoration: none;
                color: @grey-90;
                flex: 2;
            }
            &-link {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                text-align: right;
                flex: 1;
                .c-cta {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    color: @grey-90;
                }
            }

            &-people {
                max-width: none;
                margin-left: 0;
                font-size: 12px;
                margin-top: 8px;
                font-weight: 500;
                position: relative;
                white-space: nowrap;
                padding: 0px 6px 0 0;
                width: 100%;
            }

            &-persons {
                font-size: 16px;
                font-weight: 600;
                position: relative;
                padding-right: 14px;
                display: initial;
                .sprite-icon-people {
                    background-image: url(../../../../../../../etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/sprite-bhg.png);
                    background-repeat: no-repeat;
                    height: 30px;
                    background-size: 38px auto;
                    background-position: -8px -1263px;
                    transform: scale(1.33);
                }
                .svg-icon {
                    .cmp-icon {
                        padding-bottom: 4px;
                    }
                }
                
                &:after {
                    content: "";
                    position: absolute;
                    right: 6px;
                    bottom: 40%;
                    width: 4px;
                    height: 4px;
                    background: rgba(106, 112, 115, 0.5);
                    border-radius: 99px;
                }
            }

            &-adults,
            &-children {
                font-size: 11px;
                font-weight: 300;
                letter-spacing: 0.25px;
                text-transform: lowercase;
                color: @grey-50;
            }

            &-adults {
                .sprite-icon-adult {
                    width: 20px;
                    height: 30px;
                    background-image: url(../../../../../../../etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/sprite-bhg.png);
                    background-repeat: no-repeat;
                    background-size: 38px auto;
                    background-position: -10px -1108px;
                }
            }

            &-children {
                .sprite-icon-child {
                    width: 22px;
                    background-image: url(../../../../../../../etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/sprite-bhg.png);
                    background-repeat: no-repeat;
                    background-size: 40px auto;
                    background-position: -9px -1252px;
                }
            }

            &-list {
                font-size: 16px;
                line-height: 24px;
                font-weight: 300;
                position: relative;

                .sprite-icon-people {
                    transform: none;
                }
                .sprite-icon-adult {
                    background-position: -13px -1108px;
                    transform: scale(0.8);
                }
                .sprite-icon-child {
                    transform: scale(0.8);
                }

                &-ul {
                    li {
                        position: relative;
                        padding: 4px 0 4px 18px;
                        display: flex;
                        align-items: center;

                        .svg-icon.mod--size-14 {
                            position: absolute;
                            left: 0px;
                            svg {
                                margin-bottom: -2px;
                            }
                        }
                        &:first-of-type {
                            padding-top: 8px;
                        }
                        &:last-of-type {
                            padding-bottom: 8px;
                        }
                    }
                    &::before {
                        margin-left: initial;
                    }
                }

                .c-check-list li {
                    margin-bottom: 0px;

                    &:before {
                        font-size: 12px;
                    }
                }
            }
        }

        &__footer {
            padding: 6px 4px;
            border-top: 1px solid @grey-40;
            display: inline-flex;
            justify-content: space-between;
            width: 100%;
            z-index: 2;
            background: @white;
            //loading
            &.loading-dots {
                min-width: 62px;
                .common-transition;
                .c-hotel-room-list__footer-booking-info {
                    opacity: 0;
                }
            }
            .myb-icon {
                margin-top: -3px;
                cursor: pointer;
                left: 38%;
                position: absolute;
                margin-left: -36px;

                & img {
                    max-width: 26px;
                }
            }
            &-booking {
                margin: 0 auto;
                display: flex;
                width: 100%;
                padding: 10px 4px 6px;

                &-info {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 4px;
                    align-items: flex-end;

                    .c-price__type {
                        font-size: 11px;
                        line-height: 20px;
                    }
                }
            }
            &-price {
                display: flex;
                flex-flow: column;
                justify-content: flex-start;
                width: auto;
                margin-right: 2px;
                &-tag {
                    font-size: 11px;
                    line-height: 18px;
                    font-weight: 400;
                    color: @grey-50;
                    align-self: flex-start;
                    margin-right: 4px;
                }

                &-quantity {
                    font-size: 28px;
                    line-height: 32px;
                    font-weight: 600;
                    color: #155583;
                    display: flex;
                    .c-price {
                        display: inline-flex;
                    }
                    .c-price__currency,
                    .c-price__type {
                        margin-left: 0;
                    }
                    .c-price__currency:after {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
            }

            .c-cta-container {
                margin-left: auto;
                width: auto;

                .c-cta {
                    padding: 8px 32px;
                }
            }

            &-unavailable {
                font-size: 14px;
                margin: auto;
                max-width: 220px;
                .cmp-icon {
                    display: flex;
                    height: 100%;
                    background-repeat: no-repeat;
                    float: left;
                    background-position: center center;
                    margin-right: 15px;
                }

                &-title {
                    display: inline-block;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                }

                &-content {
                    display: inline;
                }
            }
        }

        .mod--available-from {
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column-reverse;
        }
    }

    .c-ribbon__not-available {
        margin: 30px 0;
        padding: 14px 10px;
        .c-ribbon__close-btn {
            display: none;
        }
        .c-ribbon__light {
            justify-content: flex-start;
            box-shadow: none;
            .c-ribbon__icon {
                margin-right: 15px;
                .svg-icon {
                    width: 43px;    
                    height: 43px;
                }
            }
            p {
                font-size: 14px;
                line-height: 1.3;
            }
        }
        .c-ribbon__light-text2 {
            padding: 10px 0px 0px;
            p {
                font-size: 13px;
                line-height: 1.3;
            }
        }
    }
    
    /* HIGHLIGHTED CARD */
    .mod--highlighted-card__label {
        display: none;
    }
    .mod--highlighted-card {
        &.c-hotel-room-list {
            box-shadow: 4px 4px 24px rgba(0, 130, 155, 0.4);
            .mod--highlighted-card__label {
                display: inline-flex;
                font-weight: 600;
                font-size: 14px;
                top: 10px;
                right: 10px;
                padding: 4px 12px 4px 8px;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
            .c-hotel-room-list__footer {
                .myb-icon {
                    top: 50px;
                    margin-left: -36px;
                }
            }
            
            .c-hotel-room-list__body {
                padding-top: 50px;
            }
        }
    }
}
// TOO LONG CURRENCIES TO BE SHORTENED
.mod--IDR {
    .hotel-room-list {
        .c-hotel-room-list {
            &__footer-price-quantity {
                font-size: 22px;
                line-height: 36px;
            }
        }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .hotel-room-list,
    .hotel-room-recommended-container {
        .default-container;
        padding-bottom: 30px;
        width: 100%;

        .c-hotel-room-list {
            margin-bottom: 24px;
            flex-flow: row;
            align-items: center;

            &__content-wrapper {
                flex-direction: initial;
            }

            &__header {
                width: 360px;
                flex-shrink: 0;
                .c-container-image {
                    height: 100%;
                }
                .mod--gradient-overlay {
                    .cmp-image {
                        &::after {
                            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%);
                            content: none; //PLASOFT-15851
                        }
                    }
                }

                .c-label {
                    font-size: 14px;
                    font-weight: 500;
                    width: auto;
                    max-width: 200px;
                    margin: 8px;
                    right: 0px;
                }
            }

            &__body {
                padding: 16px 40px;
                width: 100%;
                margin: unset;
                display: flex;
                flex-flow: column;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: wrap;

                &-header {
                    flex-wrap: initial;
                    justify-content: initial;
                    overflow: initial;
                    margin-bottom: initial;
                }

                &-title {
                    width: 100%;
                    font-size: 20px;
                    line-height: 30px;
                    flex: 4;
                }

                &-main {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }

                &-text {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 300;
                    margin-top: 16px;

                    .c-cta {
                        font-size: 16px;
                        line-height: 30px;
                    }
                }

                &-list {
                    flex-shrink: 0;
                    margin-left: initial;

                    .c-check-list li {
                        &:before {
                            font-size: 16px;
                        }
                    }
                    &-ul {
                        margin-top: 0;
                        li {
                            padding: 8px 0 0px 18px;
                            &:first-of-type {
                                padding-top: 16px;
                            }
                        }
                    }
                }

                &-people {
                    padding: 0px 30px 0 0;
                    margin-top: 16px;
                    height: 26px;
                }
                &-persons {
                    .svg-icon {
                        width: 27px;
                        height: 27px;
                    }
                }
                &-adults,
                &-children {
                    font-size: 14px;
                }
            }

            &__footer {
                border-top: 0px;
                border-left: 1px solid @grey-40;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: inherit;
                flex-flow: column;
                padding: 0px 30px;
                margin: 0px;
                width: 25%;
                height: 140px;
                flex-shrink: 0;
                background: unset;

                .myb-icon {
                    margin: 0;
                    cursor: pointer;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    left: initial;

                    & img {
                        max-width: fit-content;
                    }
                }
                &-booking {
                    display: initial;
                    padding: initial;

                    &-info {
                        margin-bottom: 24px;

                        &-claim {
                            margin-bottom: 22px;
                            width: 180px;
                            height: 20px;
                        }
                        .c-price__type {
                            font-size: 14px;
                        }
                    }
                }

                &-price {
                    &-tag {
                        font-size: 12px;
                        line-height: 28px;
                    }

                    &-quantity {
                        font-size: 36px;
                        line-height: 36px;
                        .c-price__currency:after {
                            font-size: 24px;
                            line-height: 24px;
                        }
                    }
                }

                .c-cta-container {
                    .c-cta {
                        font-size: 18px;
                        font-weight: 700;
                    }
                }

                &-unavailable {
                    >* {
                        margin: 4px 0px;
                    }

                    .cmp-icon {
                        height: 24px;
                        float: none;
                    }

                    &-title {
                        display: block;
                        font-size: 18px;
                        line-height: 24px;
                    }

                    &-content {
                        display: block;
                    }
                }
            }

            .mod--available-from {
                text-align: inherit;
            }

            .c-hotel-room-my-barcelo-price {
                .c-hotel-room-list__footer {
                    align-items: center;
                    flex-wrap: nowrap;
                    align-items: center;
                }
            }
        }
        .w-full.text-center.my-3 {
            margin: 0;
            margin-top: -24px;
            & > .c-cta.mod--cta-with-arrow-down.pagination-JS {
                &.invisible {
                    height: 0;
                    overflow: hidden;
                }
                &:not(.invisible) {
                    margin-top: 72px;
                    margin-bottom: 24px;
                    display: block;
                    overflow: visible;
                }
            }
        }
        .c-ribbon__not-available {
            padding: 24px 10px 24px 20px;
            .c-ribbon__light {
                p {
                    font-size: 20px;
                    line-height: 1.3;
                }
                .c-ribbon__icon {
                    margin-right: 30px;
                }
            }
            .c-ribbon__light-text2 {
                padding-left: 0 0 0 75px;
                p {
                    font-size: 16px;
                }
            }
        }

        /* HIGHLIGHTED CARD */
        .mod--highlighted-card {
            &.c-hotel-room-list {
                .mod--highlighted-card__label {
                    top: 12px;
                    right: 12px;
                    font-size: 18px;
                    padding: 6px 12px 6px 8px;
                }
                .c-hotel-room-list__footer {
                    margin-top: 16px;
                    .myb-icon {
                        top: 60px;
                        margin-left: -36px;
                    }
                }
                .c-hotel-room-list__body {
                    padding-top: 30px;
                }
                .c-container-image {
                    min-height: 220px;
                }
            }
        }
    }
    // TOO LONG CURRENCIES TO BE SHORTENED
    .mod--IDR {
        .hotel-room-list {
            .c-hotel-room-list {
                &__footer-price-quantity {
                    font-size: 30px;
                    line-height: 32px;
                }
            }
        }
    }
}