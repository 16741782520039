/* C-TRIPADVISOR DETAILS - MOBILE FIRST (~ TABLET) */
.c-tripadvisor-details {
    background-color: @grey-20;
    color: @text-color;
    .c-tripadvisor {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: 1px solid @grey-40;
        margin: 30px auto;
        padding-bottom: 20px;
        .icon-tripadvisor {
            width: 100%;
            justify-content: center;
            margin-top: -15px;
            &:before {
                font-size: 35px;
                font-weight: 500;
                background-color: @grey-20;
                padding: 0px 15px;
            }
        }
        .ta {
            &__score {
                margin-right: 17px;
                text-align: center;
            }
            &__dots {
                margin-right: 14px;
                justify-content: center;
            }
            &__social {
                margin-top: 4px;
            }
        }
    }
    &__content {
        &-user {
            margin-bottom: 30px;
            &-header {
                display: flex;
                justify-content: space-between;
                align-content: center;
                margin-bottom: 15px;
                .ta-profile {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .ta-avatar {
                        width: 24px;
                        height: 24px;
                        background-color: @white;
                        border-radius: @circle;
                        margin-right: 8px;
                        position: relative;
                        &:before {
                            width: 24px;
                            height: 24px;
                            text-align: center;
                            font-family: "barcelo-ui-kit";
                            content: "\e916";
                            position: absolute;
                            font-size: 14px;
                            font-weight: 700;
                            margin-top: 1px;
                        }
                    }
                    .ta-name {
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 500;
                    }
                }
                .c-timedate {
                    font-size: 12px;
                    .ta-group:before {
                        content: "·";
                        font-size: 16px;
                        font-weight: 500;
                        margin-right: 4px;
                    }
                }
            }
            &-message {
                display: flex;
                flex-flow: column;
                justify-content: flex-start;
                align-items: flex-start;
                background-color: @white;
                border-radius: 1px;
                padding: 25px;
                position: relative;
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
                &:before {
                    font-family: "barcelo-ui-kit";
                    content: "\e927";
                    position: absolute;
                    top: -15px;
                    left: 2px;
                    color: @white;
                    transform: rotate(180deg);
                    font-size: 22px;
                }
                .c-tripadvisor {
                    height: auto;
                    flex-grow: 0;
                    display: flex;
                    justify-content: flex-start;
                    max-width: 100%;
                    align-items: center;
                    flex-wrap: wrap;
                    border: 0px;
                    margin: 0px;
                    padding-bottom: 10px;
                    .ta__dots {
                        margin-bottom: 5px;
                        .ta__dot {
                            width: 14px;
                            height: 14px;
                        }
                    }

                    &--box {
                        .ta__dots {
                            height: 16px;
                            &__dot {
                                width: 16px;
                                height: 16px;
                                .mix-background-sprite(16px, 0, 0px);
                                &--half {
                                    .mix-background-sprite(16px, 1, 0px);
                                }
                                &--full {
                                    .mix-background-sprite(16px, 2, 0px);
                                }
                            }
                        }
                    }
                }
                &-title {
                    flex-grow: 0;
                    .ellipsis-multiline(18px, 26px, 2);
                    font-weight: 700;
                    margin-bottom: 10px;
                }
                &-text {
                    flex-grow: 1;
                    .ellipsis-multiline(16px, 24px, 5);
                    font-weight: 300;
                    margin-bottom: 15px;
                    height: 125px;
                    width: 100%;
                }
                &-button {
                    flex-grow: 0;
                    margin-top: auto;
                    .c-cta {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
/* C-TRIPADVISOR-DETAIS - VIEWS - MOBILE FIRST*/
.c-modal-inject.c-modal {
    .c-tripadvisor-details {
        &__content {
            &-user {
                width: 100%;
                padding: 15px 0px;
                margin: 0px;
                &-message {
                    display: block;
                    padding: 0px;
                    box-shadow: none;
                    &:before {
                        content: none;
                    }
                    &-title,
                    &-text {
                        display: inherit;
                        height: auto;
                        max-height: inherit;
                        overflow: inherit;
                        -webkit-line-clamp: inherit;
                        -webkit-box-orient: inherit;
                        text-overflow: inherit;
                    }
                    &-button {
                        display: none;
                    }
                }
            }
        }
    }
}

/* C-TRIPADVISOR-DETAILS - DESKTOP  */
@media (min-width: @desktop) {
    .c-tripadvisor-details {
        .c-tripadvisor {
            height: 300px;
            max-width: ~"calc(100% - 30px)";
            margin: 65px 15px 0px;
            display: block;
            text-align: center;
            .ta {
                &__score {
                    margin-top: 75px;
                    font-size: 28px;
                    line-height: 36px;
                    margin-bottom: 10px;
                    text-align: center;
                    display: block;
                    margin-right: 0px;
                }
                &__dots {
                    margin-right: 0px;
                }
            }
            .h-rule {
                max-width: 52px;
                margin: 15px auto;
            }
        }
        &__content {
            display: flex;
            flex-wrap: wrap;
            &-user {
                margin: 15px;
                width: ~"calc(33.3% - 30px)";
                &-header {
                    height: 40px;
                    margin-bottom: 8px;
                    .c-timedate {
                        text-align: right;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .ta-group {
                            display: block;
                            &:before {
                                content: none;
                            }
                        }
                    }
                }
                &-message {
                    padding: 25px 15px;
                    height: 300px;
                    .c-tripadvisor {
                        height: auto;
                        margin: 0px;
                    }
                    &-title {
                        .ellipsis-multiline(20px, 28px, 2);
                    }
                }
            }
        }
    }
    /* C-TRIPADVISOR-DETAIS - VIEWS - DESKTOP*/
    .c-modal-inject.c-modal {
        .c-tripadvisor-details {
            &__content {
                &-user {
                    padding: 15px;
                }
            }
        }
    }
}
