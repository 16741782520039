.c-hotel-detail {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0px 0px;
    &__text {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 45px;
    }
    &__aside {
        width: 100%;
        &-card {
            margin-top: 15px;
            margin-bottom: 45px;
            .ribbon {
                margin: 0px 0px 25px 0px;
                .c-ribbon__light {
                    justify-content: flex-start;
                    .icon {
                        margin-right: 10px;
                    }
                }
            }
            &-title {
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                margin-top: -14px;
                &-claim {
                    background: @body-bg;
                    padding: 0px 12px;
                }
                &-hotel {
                    display: block;
                }
            }
            &-header {
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                margin-top: 10px;
                padding: 0px 15px;
                display: flex;
                &-logo {
                    min-width: 79px;
                    margin-right: 15px;
                }
            }
            &-content {
                padding: 0px 15px;
            }
        }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .c-hotel-detail {
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 80px 0px;
        &__text {
            width: 50%;
            max-width: 554px;
            font-size: 18px;
            line-height: 32px;
            &.--large{
                max-width: 645px;
            }
        }
        &__aside {
            width: 45%;
            max-width: 458px;
            &.--large{
                max-width: 532px;
            }
            &-card {
                width: 100%;
                &-title {
                    font-size: 24px;
                    line-height: 28px;
                }
                &-header {
                    margin-top: 23px;
                    &-logo {
                        min-width: 94px;
                    }
                }
                &-content {
                    padding: 0px 30px;
                }
            }
        }
    }
}
