.c-hotel-services {
    &__card {
        width: 100%;
        &__list {
            display: flex;
            flex-wrap: wrap;
            margin: 26px 0px;
            .list__item {
                display: flex;
                width: 50%;
                font-size: 14px;
                line-height: 20px;
                align-items: center;
                margin-bottom: 22px;
                word-break: break-all;
                .icon {
                    max-width: 38px;
                    max-height: 38px;
                    margin: 0px 16px 0px 0px;
                    .c-icon {
                        max-height: 38px;
                    }
                }
                .cmp-text {
                    padding-right: 6px;
                    word-break: break-word;
                } 
            }
        }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .c-hotel-services {
        &__card {
            &__list {
                .list__item {
                    width: 25%;
                }
            }
        }
    }
}
