.mod--horizontal-card-compact {
	.c-card-hotel {
		width: 90vw;
		max-width: 360px;
		height: auto;
		flex-wrap: wrap;
		.c-close-btn {
			width: 16px;
			height: 16px;
			font-size: 16px;
			color: var(--b-blue);
		}
		&__image {
			width: 38%;
			height: ~"calc(100% - 65px)";
			position: absolute;
			.c-container-image {
				height: 100%;
			}
		}
		&__content {
			&-header {
				// flex-grow: 1;
				// padding: 23px 15px 0px 42%;
				padding: 8px 8px 0px 42%;
				flex-grow: initial;
				&-title {
					.c-cta {
						font-size: 16px;
						line-height: 20px;
					}
				}
				&-location {
					font-size: 14px;
					// margin-bottom: 6px;
					// padding-top: 4px;
					line-height: 1.3;
					padding-top: 0;
					margin-top: 8px;
					margin-bottom: 8px;
					&-link {
						align-items: flex-start;
					}
				}
			}
			&-body {
				flex-grow: 1;
				// padding: 0px 15px 5px 42%;
				padding: 0px 8px 8px 42%;
				&-info {
					flex-wrap: wrap;
					flex-flow: column-reverse;
					height: auto;
					&-label {
						display: none;
					}
					.c-tripadvisor {
						min-width: 100%;
						.ta__score {
							font-size: 14px;
						}
						.ta__reviews {
							display: none;
						}
					}
				}
				&-list {
					.ellipsis-multiline(14px, 1.3, 2);
					// margin-top: 6px; 
					.mod--checks {
						padding-left: 0;
					}
					&-item {
						.ellipsis-multiline(14px, 1.3, 2);
						padding-bottom: 0;
						padding-top: 8px;
						padding-left: 1.25em;
						&::before {
							left: 0;
						}
					}
				}
			}
			hr {
				display: none;
			}
 
			&-not-available {
				height: 65px;
				overflow: hidden;
				flex-wrap: nowrap;
				padding: 5px 9px;
				width: 100%;
				justify-content: space-between;
				align-items: center;
				display: flex;
				background-color: white;
				z-index: 0;
			}

			&-footer {
				flex-grow: 0;
				// height: 65px;
				overflow: hidden;
				flex-wrap: nowrap;
				// padding: 5px 9px;
				width: 100%;
				justify-content: space-between;
				align-items: center;
				display: flex;
				background-color: white;
				z-index: 0;
				height: auto;
				padding: 12px 16px;
				&-price {
					flex-flow: column;
					margin: 0px;
					&-claim {
						font-size: 12px;
						margin: 0px;
					}
					.c-price {
						&__value {
							// font-size: 22px;
						}
						&__currency,
						&__type {
							// font-size: 11px;
						}
					}
				}

				.c-cta {
					max-width: 175px;
					margin: 0px;
				}
			}
		}
		&.c-card-hotel-offer {
			.c-card-hotel {
				&__image {
					height: ~"calc(100% - 50px)";
				}
				&__content {
					&-header {
						&-title {
							padding-right: 0px;
						}
					}
					&-body {
						.c-more-info-btn {
							padding: 8px 0px 16px;
						}
						&-list {
							display: none;
						}
					}
					&-footer {
						height: auto;
						border-top: 0px;
						padding: 0px;
						background: transparent;
						.c-card-coupon__promo {
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							align-items: flex-start;
							&-hotel,
							.c-card-hotel__content-footer-location-link {
								width: 100%;
								padding: 0px 15px 0 150px;
								&-text {
									font-size: 12px;
									font-weight: 500;
								}
								.svg-icon {
									width: 16px;
								}
							}
							&-hotel {
								font-size: 14px;
								font-weight: 500;
								line-height: 20px;
							}
							.c-card-hotel__content-footer-location-link {
								.svg-icon {
									display: none;
								}
								&-txt {
									font-weight: 500;
									font-size: 12px;
									line-height: 13px;
									color: @grey-70;
								}
							}
							&-discount {
								margin-top: 24px;
								flex-wrap: nowrap;
								height: 50px;
								border-top: 1px solid #d8d8d8;
								padding: 0px 9px;
								flex-wrap: nowrap;
								background: white;
								.c-offer {
									min-width: 128px;
									width: auto;
									margin: 0px 15px 0px 0px;
									padding: 6px 15px;
									&.card-hotel-price {
										width: inherit;
										padding: 6px 8px;

										.c-offer-price {
											.c-card-hotel__content-footer-price-claim {
												align-self: flex-start;
												margin-right: 3px;
												margin-top: 4px;
											}
											.c-price {
												flex-wrap: wrap;
												max-width: 120px;
												line-height: 1;
												align-items: center;
												&__value {
													// line-height: 1;
												}
												&__currency {
													white-space: nowrap;
													// font-size: 16px;
													// line-height: 1;
												}
												&__type {
													// line-height: 1;
												}
											}
										}
									}
									&-price {
										.c-price {
											&__value {
												line-height: 16px;
											}
											&__type {
												white-space: nowrap;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.mod--ARS, .mod--LKR, .mod--RON, .mod--UYU, .mod--MAD, .mod--IDR {
	.mod--horizontal-card-compact {
		.popup-bubble-anchor {
			.c-card-hotel__content-footer-price {
				.c-price{
					line-height: 22px;
					.c-price__value {
						font-size: 26px;
						line-height: 28px;
					}
					.c-price__currency {
						&::after {
							font-size: 16px;
						}
					}
				}
			}
		}
	}
} 

@media (min-width: @desktop) {
	.mod--horizontal-card-compact {
		.popup-bubble-anchor {
			.c-card-hotel__content-footer-price {
				padding-top: 18px; 
			}
		}
	}
	.mod--ARS, .mod--LKR, .mod--RON, .mod--UYU, .mod--MAD, .mod--IDR {
		.mod--horizontal-card-compact {
			.popup-bubble-anchor {
				.c-card-hotel__content-footer-price {
					.c-price{
						.c-price__value {
							font-size: 24px;
						}
					}
				}
			}
		}
	} 
}

///* SPECIFIC STYLES FOR HORIZONTAL CARD COMPACT with myb prices *///
.mod--horizontal-card-compact {
	.popup-bubble {
		&-anchor {
			.c-card-hotel {
				&.c-card-hotel-my-barcelo-price {
					.c-card-hotel {
						&__content {
							z-index: 0;
							&-footer {
								// flex-direction: column;
								flex-direction: row;
								height: auto;
								background-color: white;
								&-price {
									flex-flow: initial;
									width: 94%;
									&-claim {
										font-size: 12px;
									}
									.c-price-normal-myb {
										padding-right: 6px;
									}
									.c-price-myb {
										margin-top: 4px;
    									margin-bottom: 4px;
										&__container {
											height: auto;
										}
										.c-card-hotel__content-footer-price-claim {
											font-size: 12px;
										}
										// .bhg-tooltip {
										// 	top: 6px;
										// 	right: 5px;
										// 	.bhg-tooltip--icon {
										// 		&:after {
										// 			font-size: 12px;
										// 		}
										// 	}
										// }
									}
								}
								.mod--cta-fullbg {
									width: 100%;
									.c-cta {
										max-width: initial;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
///* ENDS SPECIFIC STYLES FOR HORIZONTAL CARD COMPACT with myb prices *///
