
/* HEAD HOTEL - MOBILE FIRST (~ TABLET) */

/*  WITH AND WITHOUT MYB PRICES  */
.head-hotel-info {
    .c-head-hotel-info {
        max-width: @content-sizing;
        margin: auto;
        &.--new-max-width{
            max-width: @new-content-sizing;
        }
        &__booking {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            background-color: @white;
            margin: auto auto 30px;
            padding: 8px;
            .z-mask;
            width: ~"calc(100% - 32px)";
            max-width: 400px;
            height: auto;

            padding-left: 0;
            &.no-price{
                justify-content: center;
            }
            &-info {
                display: flex;
                height: 50px;
                max-height: 50px;
                &-price {
                    position: relative;
                    display: flex;
                    align-items: baseline;
                    // flex-wrap: wrap;
                    align-content: flex-end;
                    padding: 0;
 
                    // desde
                    .c-price-from {
                        font-size: 11px;
                        line-height: 24px;
                        font-weight: 400;
                        color: @grey-50;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    &-myb,
                    &-quantity {
                        font-size: 24px;
                        line-height: 36px;
                        font-weight: 600;
                        color: var(--b-blue);
                        align-self: flex-end;
                        & ~ .c-price__currency {
                            margin-right: 2px;
                            &:after {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                        .c-price__value {
                            &.mod--strike {
                                &:before {
                                    content: none;
                                }
                            }
                        }
                        .c-price__currency {
                            margin-left: 0;
                            margin-right: 0.25rem;
                            &:after {
                                font-size: 16px;
                                line-height: 20px;
                            }
                        }
                    }
                    //loading
                    &.loading-dots {
                        min-width: 62px;
                        .common-transition;
                        &> .c-price-from,
                        .c-head-hotel-info__booking-info-price-myb,
                        .c-head-hotel-info__booking-info-price,
                        .c-price__type {
                            opacity: 0;
                        }
                    }
                }
                // noche
                .c-price__curre-type-wrapper {
                    display: flex;
                    align-self: flex-end;
                    padding: 0;
                    font-size: 12px;
                    line-height: 28px;
                    font-weight: 400;
                    color: @grey-50;
                    white-space: nowrap;
                }
            }

            &-btn {
                // height: 40px;
                height: 48px;
                width: 246px;
                .c-cta {
                    min-width: 128px;
                    a {
                        color: white;
                        text-decoration: none;
                    }
                }
            }
        }
        &__info {
            display: block;
            position: relative;
            top: -18px;
            margin: 0px auto;
            // padding: 0 8px;
            padding: 0 16px;
            &-title {
                font-size: 36px;
                line-height: 44px;
                font-weight: 600;
            }
            &-resources {
                display: flex;
                flex-direction: column-reverse;
                &-map {
                    display: inline-flex;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 300;
                    color: @text-color;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
            &-tags {
                margin-top: 8px;
            }
            &-tag {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                background-color: #E8EDF0;
                padding: 4px 8px;
                height: 28px;
                border-radius: 4px;
                margin-right: 20px;
                .svg-icon {
                    width: auto;
                    margin-right: 4px;
                }
                .c-icon {
                    max-height: 18px;
                }
            }
            &-prizes {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                .svg-icon {
                    margin-right: 5px;
                    max-width: 17px;
                    max-height: 23px;
                }
            }
        }
        .c-tripadvisor {
            display: flex;
            align-items: center;
            margin: 10px 0px;
            .icon-tripadvisor {
                min-width: 20px;
                font-size: 20px;
                vertical-align: middle;
                margin-right: 8px;
            }
            .ta {
                &__score {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    color: @text-color;
                    margin-right: 8px;
                    position: relative;
                    &::after {
                        content: "·";
                        right: -9px;
                        position: absolute;
                    }
                }
                &__dots {
                    margin-right: 8px;
                }
                &__social {
                    color: @grey-70;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 300;
                    margin-left: 8px;
                    position: relative;
                    white-space: nowrap;
                    &::after {
                        content: "·";
                        left: -9px;
                        position: absolute;
                        color: @black;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    // MODIFIERS
    .c-head-hotel-info.mod--stick-bottom {
        width: 100%;
        background: @white;
        display: flex;
        max-width: inherit;
        margin: auto;
        justify-content: space-around;
        padding: 0px;
        box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: 0px;
        // z-index: 9;
        padding: 6px 0px;
        .c-head-hotel-info__booking {
            box-shadow: none;
            max-width: 100%;
            min-width: 100%;
            top: 0px;
            // margin: 0 0 0 16px;
            padding: 0px 16px;

            display: flex;
            justify-content: center;
            margin: 0;

            &-info {
                min-width: inherit;
                min-width: initial;
                // margin-right: 15px;
                margin: 0 8px;

                justify-content: center;

                .c-cta {
                    width: 116px;
                }
            }
            &-buttons {
                margin-left: 15px;
				justify-content: center;
            }
        }
        .c-head-hotel-info__info {
            display: none;
            &-title,
            &-resources {
                display: none;
            }
        }
    }

    // hack for safari
    .c-head-hotel-info.hack--head-hotel-info { 
        padding-top: 48px; 
        .c-head-hotel-info__info-tag {
            .svg-icon {
                width: 28px;
            }
        }
        &.mod--stick-bottom {
            .c-price-from {
                top: -4px;
            }
        }
    }
}

// TOO LONG CURRENCIES TO BE SHORTENED
.mod--IDR {
    .head-hotel-info {
        .c-head-hotel-info.mod--stick-bottom {
            &__booking {
                &-info-price {
                    .c-price__value {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}

.heroslider.inject-fastbooking-true + .head-hotel-info {
    .c-head-hotel-info__booking {
        top: 20px;
    }
    .c-head-hotel-info__info {
        top: 0px;
        padding-top: 30px;
    }
    .c-head-hotel-info.mod--stick-bottom {
        .c-head-hotel-info__booking {
            top: 0px;
        }
    }
}

/* royal hotel */
[data-template="hotel-royal"] {
    .head-hotel-info {
        .c-head-hotel-info__booking {
            justify-content: center;
            width: 100%;
        }
    }
}

/* HEAD HOTEL - MEDIA - DESKTOP */
@media (min-width: @desktop) {

    /*  WITH AND WITHOUT MYB PRICES  */
    .head-hotel-info {
        .c-head-hotel-info {
            display: flex;
            flex-flow: row-reverse;
            padding: 40px 0px 45px;
            justify-content: space-between;
            align-items: center;
            &.--new-max-width {
                padding: 0px 0px 45px;
            }
            &__booking {
                height: auto;
                // max-width: 532px;
                // max-width: 36%;
                max-width: 40%;
                top: 0;
                border: 0;
                // justify-content: space-between;
                justify-content: flex-end;
                padding: 0;
                margin: 0;
                &-info {
                    flex-wrap: wrap;
                    margin-right: 60px;
                    &-price {
                        height: 56px;
                        max-height: 56px;

                        // from - desde
                        .c-price-from {
                            font-size: 12px;
                            line-height: 20px;
                            line-height: 10px;
                        }
                        &-myb,
                        &-quantity {
                            font-size: 36px;
		                    line-height: 36px;
                            .c-price__currency {
                                margin-left: 0;
                                &:after {
                                    font-size: 24px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                    // noche
                    .c-price__curre-type-wrapper {
                        font-size: 14px;
                        line-height: 20px;
                    }
				}

                &-btn {
                    height: 48px;
                    width: 246px;
                    .c-cta {
                        height: 48px;
                        width: 100%;
                        min-width: 200px;
                        font-size: 18px;
                        line-height: 30px;
                        font-weight: 600;
                        // padding: 0px 32px;
                        padding: 8px 32px;
                    }
                }

                &-buttons {
                    justify-content: space-between;

                    &-btn {
                        &-txt {
                            display: block;
                            font-size: 12px;
                            font-weight: 300;
                            line-height: 12px;
                        }
                    }
                }
            }
            &__info {
                display: flex;
                flex-wrap: wrap;
                margin: 0px;
                top: 0px;
                // max-width: 50%;
                // max-width: 60%;
                max-width: 59%;
                padding: 0;
                &-title {
                    font-size: 48px;
                    line-height: 56px;
                    font-weight: 400;
                    width: 100%;
                    text-align: left;
                }
                &-resources {
                    width: 100%;
                    flex-direction: row;
                    min-width: 400px;
                    margin-right: 15px;
                    &-map {
                        font-size: 16px;
                        line-height: 24px;
                        margin-right: 26px;
                        padding-top: 4px;
                        padding-left: 4px;
                        margin-bottom: -10px;
                        &-direction {
                            margin-bottom: 16px;
                        }
                    }
                }
                .c-tripadvisor {
                    margin: 0px;
                    justify-content: flex-start;
                    .ta {
                        &__score {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
                }
            }
            &.--new-max-width{
                max-width: @new-content-sizing;
            }
        }
        
        .c-head-hotel-info.mod--stick-bottom {
            .c-head-hotel-info__booking {
                justify-content: center;
                padding: 8px 16px;
                &-info {
                    flex-flow: column;
                   
                    &-price, 
                    .c-price__curre-type-wrapper {
                        max-height: 56px;
                        line-height: 38px;
                    }
                    &-price {
                        .c-price-from {
                            line-height: 10px;
                        }
                        .c-price {
                            line-height: 56px;
                        }
                    }
                }
                &-buttons {
                    margin-left: 25px;
                }
            }
            &.--new-max-width {
                max-width: none;
                padding: 8px 16px;
            }
        }

        .c-head-hotel-info.hack--head-hotel-info { 
            padding-top: 64px; 
        }
    }

    // TOO LONG CURRENCIES TO BE SHORTENED
    .mod--IDR {
        .head-hotel-info {
            .c-head-hotel-info.mod--stick-bottom {
                &__booking {
                    &-info-price {
                        .c-price__value {
                            font-size: 36px;
                        }
                    }
                }
            }
        }
    }

    .heroslider.inject-fastbooking-true + .head-hotel-info {
        .c-head-hotel-info__booking {
            top: 0px;
        }
        .c-head-hotel-info__info {
            top: 0px;
            padding-top: 0px;
        }
    }

    /* royal hotel */
    [data-template="hotel-royal"] {
        .head-hotel-info {
            .c-head-hotel-info__booking {
                justify-content: space-between;
            }
        }
    }
}

/* HEAD HOTEL - DESKTOP - CONTENT SIZE */
@media (min-width: @desktop) and (max-width: @content-sizing) {
    .head-hotel-info {
        .c-head-hotel-info {
            padding: 20px;
            &__info {
                &-resources {
                    flex-wrap: wrap;
                    justify-content: flex-end;
                }
            }
        }
    }
}

/* HEAD HOTEL - DESKTOP - CONTENT SIZE */
@media (min-width: @desktop) and (max-width: @new-content-sizing) {
    .head-hotel-info {
        .c-head-hotel-info {
            padding: 20px;
            &__info {
                &-resources {
                    flex-wrap: wrap;
                    // justify-content: flex-end;
                }
            }
        }
    }
}