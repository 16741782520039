/* HOTEL MAP COMPONENT */
.c-hotel-map {
    width: 100%;
    margin: 50px auto;
    position: relative;
    padding-top: 45px;
    &__map {
        border: 0px;
        border-top: 1px solid @grey-40;
        width: 100%;
        height: 250px;
        margin-bottom: -10px;
    }
    &__preview {
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        min-height: 250px;
        background-size: cover;
        .c-container-image{
            height: 100%;
        }
    }

    .c-cta {
        .c-container--breakline:after {
            margin-left: 0px;
        }
        .c-container--breakline:before {
            margin-right: 0px;
        }
    }
    &__content {
        background: fade(@b-light-blue, 30%);
        padding: 35px 18px;
        &-description {
            font-size: 16px;
            line-height: 24px;
        }
        .h-rule {
            max-width: 110px;
            margin: 25px auto;
        }
        &-address {
            .unstyle-anchor;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            margin-bottom: 20px;
            display: block;
        }
        &-links {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            &-item {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }
        }
        .c-textimage {
            margin: 0;
        }
        .icon {
            margin: 0;
            margin-right: 10px;
        }
    }
}

.result__map {
    overflow: visible;
    .popup-bubble {
        padding: 0px;
        border-radius: 2px;
        &-anchor {
            bottom: 40px;
        }
    }
    .mod--horizontal-card-compact;
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .c-hotel-map {
        height: 756px;
        margin: 70px auto;
        padding: 0px;
        position: relative;
        &__map {
            height: 756px;
            margin-bottom: 0px;
        }
        .c-cta {
            position: absolute;
            top: 60px;
            right: 0px;
            width: 200px;
            .c-container--breakline:before,
            .c-container--breakline:after {
                content: none;
            }
        }
        &__content {
            overflow: hidden;
            width: 100%;
            max-width: 457px;
            height: auto;
            max-height: ~"calc(100% - 184px)";
            position: absolute;
            top: 32px;
            left: ~"calc(calc(100% - " @content-sizing ~") / 2)";
            background: #edf8fb;
            box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
            border-radius: @soft-corners;
            padding: 35px 18px;
            .h-rule {
                max-width: 100px;
                margin: 20px 0;
            }
        }
    }
    .result__map {
        .mod--horizontal-card-compact;
    }
}
@media (max-width: @content-sizing) and (min-width: @desktop) {
    .c-hotel-map__content {
        left: 2%;
    }
}
