.c-hotel-highlights {
    .section-header, .section-subheader {
        font-weight: 300;
    }
    &__wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        margin: 16px 0;
    }

    .c-textimage{
        &.--rounded{
            border-radius: 8px;
            border: 1px solid #d5dee4;
            padding: 24px 8px;
            width: 100%;
        }
    }
    &__modal{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        background-color: rgba(0,0,0,.85);
        &.--open{
            visibility: visible;
            opacity: 1;
            pointer-events: all;
        }
        &-content{
            width: 100%;
            height: 100%;
            overflow-y: auto;
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 72px 16px 45px;
            max-width: 1312px;
            position: relative;
            
            /* firefox */
            scrollbar-width: auto;
            scrollbar-color: lightgrey #ffffff;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color:lightgrey;
            }
            &::-webkit-scrollbar-track {
                background-color: #ffffff;
            }

            .c-section__headings {
                margin-top: 0;
            }
        }
        &-wrapper{
            width: 100%;
        }
        &-close{
            align-self: flex-end;
            position: absolute;
            right: 17px;
            top: 25px;
            &-label{
                margin-top: 40px;
            }
        }
        .c-container{
            &__grid-wrapper{
                padding: unset;
                max-width: unset;
                margin: unset;
            }
        }
    }
    &__services{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
        margin: 40px 0 0;
        width: 100%;
    }
    &__service{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px 0;
        &-header{
            padding-bottom: 5px;
            border-bottom: 1px solid #d7d9da;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            &__title{
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0.0015em;
                margin-left: 8px;
            }
        }
        &-child{
            font-size: 18px;
            line-height: 28px;
            color: #5f5f5f;
        }
    }
    &.mod--royalLevel {
        padding: 70px 15px 68px 15px;
        margin: 0 auto;
        .section-header {
            font-size: 30px;
            line-height: 34px;
            margin-bottom: 24px;
        }
        .c-hotel-highlights__wrapper {
            margin: 0;
            padding: 40px 0 42px 0;
            gap: 42px 0;
            border-width: 1px 0 1px 0;
            border-color: white;
            filter: brightness(0) invert(1);

            &.textimage {
                .c-textimage {
                    padding: 0;
                    .cmp-text {
                        font-size: 15px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .c-hotel-highlights{
        &__wrapper{
            grid-template-columns: repeat(3, 1fr);
            gap: 16px 24px;
        }
        .c-textimage{
            &.--rounded{
                .cmp-text{
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        &__modal{
            padding: 16px;
            &-content{
                padding: 80px 40px 56px;
                height: auto;
                max-height: 100%;
                border-radius: 16px;

                /* firefox */
                &::-webkit-scrollbar {
                    width: 24px;
                }
                &::-webkit-scrollbar-thumb {
                    border: 8px solid transparent;
                    background-clip: content-box;
                    border-radius: 16px;
                }
                &::-webkit-scrollbar-track {
                    background-color: transparent;
                    border-radius: 16px;
                    margin: 12px 0;
                }
            }
            &-close{
                top: 42px;
                right: 42px;
                &-label{
                    margin-top: 50px;
                }
            }
        }
        &__services{
            grid-template-columns: repeat(3, 1fr);
            gap: 40px 24px;
            margin: 40px 0 0;
        }
        &__service{
            &-header{
                padding-bottom: 8px;
                &__title{
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 32px;
                    letter-spacing: 0;
                    margin-left: 16px;
                }
            }
        }
        &.mod--royalLevel {
            padding: 70px 0 68px 0;
        }
    }
}