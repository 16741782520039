/* SINGLE HOTEL MEETING ROOMS FILTERS - MOBILE FIRST (~ TABLET) */
.hotel-meeting-rooms {//container
	position: relative;
	padding-bottom: 45px;
	.c-hotel-meeting-rooms {
		.c-section__headings {
			padding: 48px 15px 0 15px;
			&:after {
				content: "";
				height: 237px;
				width: 200vw;
				position: absolute;
				background: #F5F5F6;
				left: -50vw;
				top: 0;
				z-index: -1;
			}
		}
		.c-hotels-comparator {
			.c-hotels-comparator__sidebar {
				width: 100%;
				padding: 24px;
				position: relative;
				left: 0;
				margin-bottom: 24px;
				&:after {
					content: "";
					height: 100%;
					width: 120%;
					position: absolute;
					background: #F5F5F6;
					left: -36px;
					top: 0;
					z-index: -1;
				}
				.sidebar__header {
					// justify-content: center;
					justify-content: flex-start;
					position: relative;
					left: 0;
					background: none;
					box-shadow: none;
					padding: 0;
					.sidebar__title {
						font-weight: 300;
						font-size: 16px;
						line-height: 24px;
						color: @grey-90;
					}
				}
				.sidebar__facets-list {
					.dimension-content { //all
						display: flex;
						input[type="checkbox"] {
							width: 0;
							height: 0;
							margin: 0;
							overflow: visible;
							border: 0;
							box-shadow: none;
							z-index: -1;
							top: 0;
							left: 0;
							position: absolute;
						}
						.meeting-facet {
							position: relative;
						}
					}
					[data-dimension="CAPACITY"] {
						.dimension-content {
							max-width: 450px;
							margin: 0 auto;
							justify-content: space-between;
							visibility: hidden;
							position: absolute;
						}
						.meeting-facet {
							padding-right: 30px;
						}
						label {
							display: flex;
							flex-direction: column;
							align-items: center;
							font-size: 14px;
							font-weight: 400;
						}
						.icon {
							display: none;
						}
						input[type="checkbox"] {
							&:checked {
								border-radius: 16px;
								top: -30px;
								&:after {
									content: "";
								}
							}
						}
					}
					.dimension:not([data-dimension="CAPACITY"]) {
						margin-top: 32px;
						.dimension-content {
							flex-wrap: wrap;
							justify-content: flex-start;
							.meeting-facet {
								display: flex;
								align-items: center;
								// border: 1px solid var(--b-aquamarine-dark-blue);
								border: 1px solid @grey-90;
								background: transparent;
								border-radius: 8px;
								overflow: hidden;
								z-index: 0;
								margin-right: 8px;
								margin-bottom: 8px;
								.common-transition;
								
								label {
									font-size: 16px;
									line-height: 24px;
									font-weight: 400;
									display: flex;
									align-items: center;
									padding: 8px 12px;
									cursor: pointer;
									// color: var(--b-aquamarine-dark-blue);
									color: @grey-90;
								}
								.icon {
									margin-right: 11px;
								}
								.c-icon {
									// filter: brightness(0) invert(0.2);
									filter: brightness(0) saturate(100%) invert(21%) sepia(9%) saturate(749%) hue-rotate(155deg) brightness(93%) contrast(84%);
								}
								input[type="checkbox"] {
									&:checked:after {
										content: "";
										width: 260px;
										height: 40px;
										background: var(--b-aquamarine-dark-blue);
										display: block;
									}
									&:checked {
										& ~ .icon {
											filter: brightness(0) invert(1);
										}
										& ~ span {
											color: @white;
										}
									}
								}
								.count {
									display: none;
								}
							}
						}
						.level-filter {
							display: none;
						}
					}

					// filter slider
					.range-slider {
						display: flex;
						flex-flow: row wrap;
						align-items: flex-start;
					    flex-flow: column wrap;
						.number-group {
							flex: 1 0 auto; 
							height: 30px; 
							font-weight: 400;
							font-size: 14px;
							line-height: 20px;
							color: @white; 
							width: 262px;
							position: relative;
							margin-bottom: 8px;

							&-input {
								position: absolute;
								bottom: 0;
								width: 80px;
								height: 30px;
								background: rgba(245, 245, 246, 0.7);
								&:after {
									content: "pax";
									position: relative;
									color: @grey-90;
									bottom: 0;
									top: 5px;
									left: 48px;
								}
								&__plus:before{
									content: "+";
									position: relative;
									color: @grey-90;
									bottom: 0;
									top: 5px;
									left: 18px;
								}
							}
							.number-input {
								width: 80px; 
								height: 30px; 
								text-align: right; 
								color: @grey-90; 
								border: 0; 
								position: absolute;
								bottom: 0;
								padding-right: 34px;
								background: transparent;
					
								&::-webkit-outer-spin-button,
								&::-webkit-inner-spin-button {
									-webkit-appearance: none;
								}
					
								&:invalid,
								&:out-of-range {
									color: red;
								}
					
							}
					
						}
						.range-group {
							position: relative; 
							flex: 0 0 100%; 
							height: 2px;
							width: 262px;
							.rangeslider--fill {
								position: absolute;
								height: 2px;
								width: auto;
								bottom: 0;
								background: linear-gradient(to right,#30a2bf 12.01%,#315973 60.41%);
								z-index: 1;
								max-width: 262px;
							}
							.range-input {
								position: absolute; 
								left: 0; 
								bottom: 0; 
								margin-bottom: 0; 
								-webkit-appearance: none; // vendor-prefix exception
								width: 100%; 
								border-bottom: 0; 
								width: 262px;
								
								&:focus {
									outline: 0; 
								}
								&::-webkit-slider-runnable-track {
									width: 100%; 
									height: 2px; 
									cursor: pointer; 
									animation: .2s; 
									background: grey; 
									border-radius: 1px; 
									box-shadow: none; 
									border: 0; 
								}
							
								&::-webkit-slider-thumb {
									z-index: 2; 
									position: relative; 
									height: 16px; 
									width: 16px; 
									border-radius: 50%; 
									background: var(--b-aquamarine-blue);
									cursor: pointer; 
									-webkit-appearance: none; 
									margin-top: -7px; 
								}
								&.range-input--max {
									&::-webkit-slider-thumb {
										// background: var(--b-aquamarine-dark-blue);
										background: @b-aquamarine-dark-blue;
									}
								}
								
								&::-moz-range-track {
									width: 100%; 
									height: 2px; 
									cursor: pointer; 
									animation: .2s; 
									background: var(--b-aquamarine-blue);
									border-radius: 1px; 
									box-shadow: none; 
									border: 0; 
								}
								
								&::-moz-range-thumb {
									z-index: 2; 
									position: relative; 
									box-shadow: 0px 0px 0px @black;
									border: 1px solid var(--b-aquamarine-blue);
									height: 16px; 
									width: 16px; 
									border-radius: 50%; 
									background: var(--b-aquamarine-blue);
									cursor: pointer; 
								}
								
								&::-ms-track {
									width: 100%; 
									height: 5px; 
									cursor: pointer; 
									animation: .2s; 
									background: transparent; 
									border-color: transparent; 
									color: transparent; 
								}
								
								&::-ms-fill-lower,
								&::-ms-fill-upper {
									background: var(--b-aquamarine-blue);
									border-radius: 1px; 
									box-shadow: none; 
									border: 0; 
								}
								
								&::-ms-thumb {
									z-index: 2; 
									position: relative; 
									height: 16px; 
									width: 16px; 
									border-radius: 50%; 
									background: var(--b-aquamarine-blue);
									cursor: pointer; 
								}
							}
						}
					}
				}
				.sidebar__footer {
					.delete-filters-btn {
						text-align: right;
						margin-top: 24px;
						font-weight: 400;
						font-size: 16px;
						line-height: 24px;
					}
				}
			}
			.c-hotels-comparator__body {
				width: 100%;
				margin: 0;
				.body__main-nav-wrapper { //compare buttons
					display: none;
				}
			}
			.c-hotel-meeting-room-list {//item
				background-color: @body-bg;
				// width: 100%;
				width: calc(100% - 32px);
				margin: 0px auto 24px;
				display: flex;
				flex-flow: column;
				overflow: hidden;
				position: relative;
				.mod--rounded-skin__class;
				border: 1px solid #D7D9DA;
				&__content-wrapper {
					width: 100%;
					display: flex;
					flex-direction: column;
				}
				&__header {
					position: relative;
					width: 100%;
					.c-container-image {
						height: 220px;
						width: auto;
					}
				}
				&__body {
					width: 100%;
					padding: 20px 16px;
					&-header {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						overflow: visible;
						margin-bottom: -5px;
						flex-direction: column;
						width: 100%;
					}
					&-tile {
						flex: 2;
						h3 {
							font-weight: 600;
							font-size: 20px;
							line-height: 30px;
						}
					}
					&-icons-area {
						display: flex;
						flex-direction: row;
						margin-top: 10px;
						&-item {
							margin-right: 18px;
							font-weight: 400;
							font-size: 14px;
							line-height: 20px;
							.svg-icon {
								width: 20px;
								filter: grayscale(1);
							}
						}
					}
					&-main {
						margin: 16px 0;
						.c-hotel-meeting-room__modal__section-title {
							display: none;
						}
						.hotel-meetingrooms-icons {
							width: 100%;
							// display: flex;
							// flex-wrap: wrap;
							display: grid;
							grid-template-columns: repeat(auto-fit, minmax(146px, 1fr));
							&__item {
								display: flex;
								align-items: center;
								padding: 8px 10px;
								border-radius: 4px;
								margin-right: 5px;
								margin-bottom: 4px;
								.common-transition;
								.icon {
									margin-right: 7px;
									filter: brightness(0) invert(0);
								}
								&.mod--active {
									background: var(--b-aquamarine-dark-blue);
									box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
									.icon {
										filter: brightness(0) invert(1);
									}
									.list__item-name,
									span {
										color: #ffffff;
									}
								}
								.list__item {
									&-name {
										font-weight: 300;
										font-size: 12px;
										line-height: 16px;
										letter-spacing: 0.004em;
									}
									&-pax {
										color: #6A7073;
									}
								}
							}
						}
					}
					&-link {
						font-size: 16px;
						line-height: 24px;
						font-weight: 400;
						text-align: left;
						display: flex;
						flex: 1;
						.cta-download {
							display: none;
						}
					}
					.c-hotel-meeting-room-list__footer-list {
						display: none;
					}
				}
				&__footer {
					padding: 6px 4px;
					border-top: 1px solid #d8d8d8;
					display: inline-flex;
					justify-content: space-between;
					width: 100%;
					z-index: 2;
					background: #ffffff;
					&-list {
						height: 100%;
						&-ul {
							height: 100%;
							display: flex;
							flex-direction: column;
							justify-content: space-evenly;
							.svg-icon {
								margin-right: 10px;
							}
						}
					}
				}
			}
		}
		.meeting-empty-message {
			text-align: center;
			margin: 50px 0 25px;
			font-size: 18px;
			line-height: 24px;
			font-weight: 400;
		}
	}
}
/* SINGLE HOTEL MEETING ROOMS FILTERS - DESKTOP  */
@media (min-width: @desktop) {
	.hotel-meeting-rooms {
		margin-left: inherit;
		padding-bottom: 30px;
		width: 100%;
		.c-hotel-meeting-rooms {
			.c-section__headings {
				padding-top: 77px;
				margin-top: 0;
				text-align: center;
			}
			.c-hotels-comparator {
				.c-hotels-comparator__sidebar {
					padding: 24px;
					left: initial;
					background: @white;
					.mod--rounded-skin;
					&:after {
						content: none;
					}
					.sidebar__header {
						justify-content: center;
						.sidebar__title {
							text-align: center;
						}
					}
					.sidebar__facets-list {
						max-width: 1090px;
						margin: 0 auto;
						.dimension:not([data-dimension="CAPACITY"]) {
							.sidebar__header {
								display: none;
							}
							.dimension-content {
								.meeting-facet {
									margin-right: 11px;
									&:hover {
										background: var(--b-aquamarine-dark-blue);
										.icon {
											filter: brightness(0) invert(1);
										}
										span {
											color: @white;
										}
									}
									&:last-of-type {
										margin-right: 0;
									}
								}
							}
						}
						.dimension[data-dimension="DISTRIBUTION"] {
							.dimension-content {
								justify-content: space-between;
							}
						}
						// filter slider
						.range-slider {
							align-items: center; 
							.number-group {
								&-input {
									background: rgba(255, 255, 255, 0.7);
								}
							}
						}
					}
					.sidebar__footer{
						max-width: 1090px;
						margin: 0 auto;
					}
				}
				.c-hotel-meeting-room-list {
					margin-bottom: 24px;
					flex-flow: row;
					align-items: center;
					width: 100%;
					&__content-wrapper {
						flex-direction: initial;
					}
					&__header {
						width: 342px;
						flex-shrink: 0;
						.c-container-image {
							height: 100%;
						}
					}
					&__body {
						margin: unset;
						display: flex;
						flex-flow: column;
						align-items: flex-start;
						justify-content: flex-start;
						flex-wrap: wrap;
						&-header {
							flex-wrap: initial;
							justify-content: initial;
							overflow: initial;
							margin-bottom: initial;
						}
						&-title {
							width: 100%;
							flex: 4;
							h3 {
								font-size: 24px;
								line-height: 34px;
							}
						}
						&-main {
							display: flex;
							align-content: space-between;
							width: 100%;
						}
						&-link {
							.cta-see {
								display: none;
							}
							.cta-download {
								display: block;
							}
						}
					}
					&__footer {
						border-top: 0px;
						border-left: 1px solid #d8d8d8;
						display: flex;
						justify-content: center;
						text-align: inherit;
						flex-flow: column;
						padding: 0px 30px;
						margin: 0px;
						width: 25%;
						height: 140px;
						flex-shrink: 0;
						background: unset;
						// height: 220px;
						max-height: 220px;
						min-height: 182px;
						align-items: flex-start;
					}
				}
			}
		}
	}

}

// MODAL 
.c-hotel-meeting-room__modal {
	&-content {
		.swiper {
			.swiper-wrapper {
				height: 260px;
			}
			.swiper-slide {
				.asset-image {
					height: 260px;
				}
			}
			.swiper-pagination-bullets {
				bottom: 0;
			}
		}
	}
	
	.c-card-hotel__content {
		padding: 40px 16px 60px;
		.c-hotel-meeting-room-list__body {
			&--title {
				h3 {
					font-weight: 600;
					font-size: 24px;
					line-height: 34px;
				}
			}
			&-icons-area {
				display: flex;
				flex-direction: row;
				margin-top: 10px;
				&-item {
					margin-right: 18px;
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					.svg-icon {
						width: 20px;
					}
				}
			}
			.c-hotel-meeting-room-list__footer-list {
				display: block;
				padding: 40px 0;
				li {
					margin-bottom: 16px;
					&:last-of-type {
						margin-bottom: 0;
					}
					.svg-icon {
						margin-right: 10px;
					}
				}
			}
			&-main {
				margin-bottom: 48px;
				.c-hotel-meeting-room__modal__section-title {
					font-weight: 300;
					font-size: 20px;
					line-height: 30px;
					margin-bottom: 16px;
				}
				.hotel-meetingrooms-icons {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					&__item {
						display: flex;
						align-items: center;
						padding: 8px 10px;
						border-radius: 4px;
						width: 50%;
						.icon {
							margin-right: 7px;
							filter: brightness(0) invert(0);
						}
						&.mod--active {
							background: var(--b-aquamarine-dark-blue);
							box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
							.icon {
								filter: brightness(0) invert(1);
							}
							.list__item-name,
							span {
								color: #ffffff;
							}
						}
						.list__item {
							&-name {
								font-weight: 300;
								font-size: 12px;
								line-height: 16px;
								letter-spacing: 0.004em;
							}
							&-pax {
								color: #6A7073;
							}
						}
					}
				}
			}
			&-link {
				display: flex;
				.c-cta {
					font-weight: 400;
					font-size: 16px;
					line-height: 24px;
				}
				.cta-see {
					display: none;
				}
				.cta-download {
					display: block;
				}
			}
		}
	}
}
@media (min-width: @desktop) {
	.c-hotel-meeting-room__modal {
		&-content {
			.swiper {
				.swiper-wrapper {
					height: auto;
				}
				.swiper-slide {
					.asset-image {
						height: auto;
					}
				}
			}
		}	
	}
}

// CAROUSEL MODAL 
.mod--carousel-dropped-dots,
.c-hotel-meeting-room__modal {
	.swiper {
		.swiper-pagination.swiper-pagination-bullets {
			display: flex;
			gap: 8px;
			justify-content: center;
			position: absolute;
			bottom: 27px;
			.swiper-pagination-bullet {
				border-radius: 6px;
				width: 6px;
				height: 6px;
				background: #fff;
				margin: unset;
			}
			.swiper-pagination-bullet-active {
				width: 20px;
				border-radius: 100px;
				border: unset;
				transform: none;
			}
		}
	}
	& ~ .tingle-modal__close {
		position: absolute;
		width: 40px;
		height: 40px;
		right: 16px;
		top: 16px;
		background: rgba(11, 13, 14, 0.6);
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.tingle-modal__closeIcon {
			color: #FFFFFF;
			width: 18px;
			height: 18px;
		}
	}
}