/* HOTEL MAP COMPONENT */
.c-hotel-map-card {
    flex-direction: column;

    &__card{
        width: 100%;
        border-radius: 8px;
        padding: 0 0 24px 0;
        border: 1px solid #d7d9da;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        overflow: hidden;
    }
    &__header{
        position: relative;
        width: 100%;
        height: 162px;
        &-icon{
            position: absolute;
            width: 40px;
            height: 40px;
            top: 24px;
            right: 21px;
        }
        &-preview{
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
    }
    &__content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 16px;
        width: 100%;
        &-ribbon{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px 8px;
            background: #DDECD4;
            border-radius: 2px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.0025em;
            color: #2D6229;
            font-weight: 400;
            margin-bottom: 8px;
        }
        &-title{
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.005em;
            color: #384044;
            margin-bottom: 8px;
        }
        &-subtitle{
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.005em;
            color: #384044;
            margin-bottom: 24px;
        }
        &-list{
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.005em;
            color: #888C8F;
            margin-bottom: 24px;
            width: 100%;
            &-item{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                &-left{
                    text-align: left;
                }
                &-right{
                    text-align: right;
                    white-space: nowrap;
                    padding-left: 16px;
                }
                &:nth-child(n + 2){
                    margin-top: 8px;
                }
            }
            &::before {
                content: "";
                margin: 0;
            }
        }
        &-button{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.005em;
            color: #384044;
        }
        &-links-item {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            letter-spacing: 0.035px;
            margin-bottom: 0;
            text-decoration: none;
            &:last-of-type {
                margin-bottom: 24px;
            }
        }
    }
    &__modal{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        background-color: rgba(0,0,0,.85);
        &.--open{
            visibility: visible;
            opacity: 1;
            pointer-events: all;
        }
        &-content{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 1312px;
            position: relative;
            overflow-y: auto;
        }
        &__map-wrapper{
            width: 100%;
            height: 350px;
            position: relative;
            &__map{
                width: 100%;
                height: 100%;
            }
            &__preview{
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center right;
                background-size: cover;
            }
        }
        &__wrapper{
            &-outter{
                width: 100%;
                height: calc(100% - 350px + 30px);
                padding: 68px 8px 16px 16px;
                background-color: white;
                border-radius: 24px 24px 0px 0px;
                margin-top: -30px;
                z-index: 1;
                position: relative;
            }
            &-inner{
                width: 100%;
                height: 100%;
                overflow-y: auto;
                padding-right: 8px;
                padding-bottom: 16px;
                &::-webkit-scrollbar {
                    width: 6px;               /* width of the entire scrollbar */
                }
                &::-webkit-scrollbar-track {
                    background: #D5DEE4;        /* color of the tracking area */
                }
                
                &::-webkit-scrollbar-thumb {
                    background-color: #30A2BF;    /* color of the scroll thumb */
                    border-radius: 8px;       /* roundness of the scroll thumb */
                }
            }
            &-title{
                font-weight: 600;
                font-size: 24px;
                line-height: 34px;
                color: #384044;
                text-align: left;
                margin-bottom: 16px;
            }
            &-points{
                display: flex;
                flex-direction: column;
                gap: 16px;
                margin-top: 40px;
            }
            &__accordion{
                padding: 12px 16px 12px 12px;
                border: 1px solid #D5DEE4;
                border-radius: 16px;
                &__header{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;
                    height: 56px;
                    &-preview{
                        border-radius: 8px;
                        width: 56px;
                        height: 56px;
                        object-fit: cover;
                        margin-right: 16px;
                    }
                    &-title{
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.005em;
                        color: #384044;
                        padding-right: 8px;
                        text-align: left;
                    }
                    &-arrow{
                        transform: rotateZ(0deg);
                        transition: all 0.6s ease;
                        color: #384044;
                        margin-left: auto;
                        width: 16px;
                    }
                    .cmp-image {
                        max-width: 56px;
                        margin-right: 16px;
                        &__image {
                            margin-left: 16px;
                        }
                    }
                }
                &__content{
                    // display: none;
                    overflow: hidden;
                    padding: 0;
                    transition: all 0.6s ease;
                    height: 0px;
                    .cmp-image {
                        height: 300px;
                        width: 100%;
                    }
                    .c-hotel-map-card__swiper-image {
                        height: 260px;
                        width: auto;
                    }
                }
                &.--open{
                    .c-hotel-map-card__modal__wrapper__accordion{
                        &__header{
                            &-preview{
                                display: none;
                            }
                            &-title{
                                margin-left: 0;
                            }
                            &-arrow{
                                transform: rotateZ(180deg);
                            }
                            & > .cmp-image {
                                display: none;
                            }
                        }
                        &__content{
                            // display: block;
                            padding: 16px 0;
                            height: auto;
                            .c-hotel-map-card__swiper-image {
                                height: 260px;
                            }
                        }
                    }
                }
            }
        }
        &__read-more{
            &__description{
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.005em;
                color: #384044;
                margin-bottom: 16px;
                &.--more{
                    display: none;
                }
            }
            &__button{
                display: flex;
                flex-direction: row;
                align-items: center;
                &-text{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.005em;
                    text-decoration-line: underline;
                    color: #384044;
                    &.--less{
                        display: none;
                    }
                }
                &-icon{
                    color: #384044;
                    width: 16px;
                    margin-left: 7px;
                    transition: all 0.6s ease;
                    transform: rotateZ(0deg);
                }
            }
            &.--open{
                .c-hotel-map-card__modal__read-more__description.--more{
                    display: block;
                }
                .c-hotel-map-card__modal__read-more__button{
                    &-text{
                        &.--more{
                            display: none;
                        }
                        &.--less{
                            display: inline;
                        }
                    }
                    &-icon{
                        transform: rotateZ(180deg);
                    }
                }
            }
        }
        &-close{
            position: absolute;
            right: 16px;
            top: 16px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
            background-color: rgba(11, 13, 14, 0.6);
            &-svg{
                width: 18px;
            }
        }
        &-expand{
            position: absolute;
            top: 24px;
            left: calc(50% - 20px);
            width: 40px;
            height: 4px;
            background: #D7D9DA;
            border-radius: 100px;
        }
    }

    &__swiper{
        position: relative;
        &-image{
            width: 100%;
            height: 260px;
            border: 1px solid #D5DEE4;
            border-radius: 8px;
            margin-bottom: 40px;
            object-fit: cover;
        }
        &-title{
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.0015em;
            color: #384044;
            margin-bottom: 8px;
        }
        &-subtitle{
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.0015em;
            color: #384044;
            margin-bottom: 8px;
        }
        &-description{
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.005em;
            color: #384044;
        }
        .swiper-button-prev{
            top: 276px;
            right: 65px;
            width: 32px;
            height: 32px;
            left: unset;
            margin-top: unset;
            transform: rotateZ(180deg);
            .svg-icon{
                width: 32px;
                height: 32px;
            }
            &::after{
                content: unset;
            } 
        }
        .swiper-button-next{
            top: 276px;
            right: 0;
            width: 32px;
            height: 32px;
            left: unset;
            margin-top: unset;
            .svg-icon{
                width: 32px;
                height: 32px;
            }
            &::after{
                content: unset;
            } 
        }
    }
}

// MEDIA - Mobile & Tablet
@media (max-width: @desktop) {
    .c-hotel-map-card {
        display: flex;
        justify-content: space-around;
        &__card {
            width: var(--mobile-width);
            height: var(--mobile-height);
        }
        &__modal {
            &__wrapper-outter {
                &.--expanded {
                    height: calc(100% - 105px + 30px);
                }
            }
            &__map-wrapper {
                &.--expanded {
                    height: 105px;
                }
            }
        }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .c-hotel-map-card {
        &__card{
            width: var(--desktop-width);
            height: var(--desktop-height);
            border-radius: 16px;
            gap: 32px;
        }
        &__header{
            height: 221px;
            &-preview {
                background-size: initial;
                background-position: 80% 50%;
            }
        }
        &__content{
            padding: 0 24px;
            &-title{
                font-size: 20px;
                line-height: 30px;
            }
        }
        &__modal{
            padding: 16px;
            &-close{
                top: 32px;
                right: 24px;
                width: 48px;
                height: 48px;
                &-svg{
                    width: 22px;
                }
            }
            &-expand{
                display: none;
            }
            &-content{
                border-radius: 16px;
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.3);
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                max-height: 882px;
            }
            &__map-wrapper{
                width: 48%;
                height: 100%;
            }
            &__wrapper{
                &-outter{
                    width: 52%;
                    height: 100%;
                    margin-top: unset;
                    border-radius: unset;
                    padding: 56px 95px 56px 64px;
                }
                &-inner{
                    padding-right: 24px;
                    padding-bottom: unset;
                }
                &-title{
                    font-size: 28px;
                    line-height: 36px;
                    letter-spacing: 0.0015em;
                }
                &__accordion{
                    .c-hotel-map-card__modal__wrapper__accordion{
                        &__content{
                            .cmp-image {
                                height: 300px;
                                width: 464px;
                            }
                            .c-hotel-map-card__swiper-image {
                                height: 260px;
                                width: auto;
                            }
                        }
                    }
                }
            }

            &__read-more{
                &__description{
                    &:first-of-type:not(:last-of-type) {
                        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 84.17%, #FFFFFF 100%), #384044;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }
                &.--open{
                    .c-hotel-map-card__modal__read-more__description{
                        background: unset;
                        -webkit-text-fill-color: unset;
                    }
                }
            }
        }
    }
}
