/* HOTEL ROOM RECOMENDED - MOBILE FIRST (~ TABLET) */
.hotel-room-recommended-container {
    .c-hotel-room-list {
        margin: 66px auto 24px;
        overflow: visible;
       
        &__header {
            .c-label {
                &.c-label--outside-top-left {
                    display: inline-table;
                    width: auto;
                    max-width: initial;

                    top: -46px;
                    left: 0;
                    height: 60px;
                    padding: 12px 24px 24px;
                    margin: 0;
                    z-index: 0;
                    
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;  
                    letter-spacing: 0.08px;                
                }
            }

            .c-container-image {
                border-radius: 16px 16px 0 0;
                overflow: hidden;
            }
        }

        &__body {
            &-people {
                background: @grey-20;
                padding: 8px;
                border-radius: 4px;
                height: auto;
                overflow: scroll;
            }

            &-persons {
                font-size: 14px;
                
                &:after {
                    content: "";
                    background: @grey-44;
                }
            }

            &-adults,
            &-children,
            &-room-size {
                color: @grey-75;
                position: relative;
            }

            &-room-size {
                padding-left: 14px;
                font-weight: 300;
                &::before {
                    content: "";
                    position: absolute;
                    left: 6px;
                    bottom: 40%;
                    width: 4px;
                    height: 4px;
                    background: @grey-44;
                    border-radius: 99px;
                }
            }
        }

        // &__footer {
        //     border-radius: 0 0 16px 16px;
        //     overflow: hidden;
        // }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .hotel-room-recommended-container {
        .c-hotel-room-list {
            margin: 86px auto 24px;

            &__header {
                .c-container-image {
                    border-radius: 16px 0 0 16px;
                }
            }

            &__body {
                &-people {
                    padding: 8px;
                    height: auto;
                    overflow: unset;
                }
                &-adults,
                &-children,
                &-room-size {
                    font-size: 14px;
                }
            }
        }
    }
}